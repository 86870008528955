// src/App.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Tabs, Tab, Box, Container } from '@mui/material';
import Login from './components/Login';
import Register from './components/Register';
import SetMccInfo from './components/SetMccInfo';
import AccountList from './components/AccountList';
import ShareMccAccount from './components/ShareMccAccount';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const handleLoginSuccess = (userData) => {
    setIsLoggedIn(true);
    setUsername(userData.username);
    localStorage.setItem('username', userData.username);
  };

  const handleLogout = () => {
    const confirmed = window.confirm("确定要退出登录吗？");
    if (confirmed) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      setIsLoggedIn(false);
      setTabIndex(0);
      setUsername('');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // 获取可见的标签页
  const getVisibleTabs = () => {
    const tabs = [{ label: "账户总览", component: <AccountList /> }];
    
    if (username === 'admin') {
      tabs.push(
        { label: "设置", component: <SetMccInfo /> },
        { label: "共享", component: <ShareMccAccount /> }
      );
    }
    
    return tabs;
  };

  const visibleTabs = getVisibleTabs();

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            百度账户管理系统
          </Typography>
          {isLoggedIn && (
            <>
              <Typography variant="body1" sx={{ mr: 2 }}>
                {username}
              </Typography>
              <Button color="inherit" onClick={handleLogout}>
                退出登录
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      {!isLoggedIn ? (
        isRegistering ? (
          <Container maxWidth="xs">
            <Register onRegisterSuccess={() => setIsRegistering(false)} />
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Button onClick={() => setIsRegistering(false)}>已有账户？登录</Button>
            </Box>
          </Container>
        ) : (
          <Container maxWidth="xs">
            <Login onLoginSuccess={handleLoginSuccess} />
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Button onClick={() => setIsRegistering(true)}>没有账户？注册</Button>
            </Box>
          </Container>
        )
      ) : (
        <>
          <Tabs 
            value={tabIndex} 
            onChange={handleTabChange} 
            centered 
            sx={{ mt: 2 }}
          >
            {visibleTabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>

          <Box sx={{ p: 3 }}>
            {visibleTabs[tabIndex]?.component}
          </Box>
        </>
      )}
    </div>
  );
}

export default App;
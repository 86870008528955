// src/components/Login.js
import React, { useState } from 'react';
import axiosInstance from '../axiosInstance';
import { TextField, Button, Box, Typography, Container, Snackbar, Alert } from '@mui/material';

const Login = ({ onLoginSuccess }) => {
  // 基本状态管理
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // 简化的表单提交处理
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axiosInstance.post('/login', {
        username: username.trim(),
        password: password
      });

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', username.trim());
      
      setSnackbarOpen(true);
      
      onLoginSuccess({ 
        username: username.trim(),
        ...response.data
      });
    } catch (error) {
      setError(error.response?.data?.message || '登录失败');
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ mt: 5 }}
      >
        <Typography 
          variant="h5" 
          align="center" 
          gutterBottom
        >
          登录
        </Typography>

        {/* 简化的用户名输入框 */}
        <TextField 
          fullWidth
          label="用户名" 
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        {/* 简化的密码输入框 */}
        <TextField 
          fullWidth
          label="密码" 
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <Button 
          type="submit" 
          fullWidth 
          variant="contained" 
          sx={{ mt: 3, mb: 2 }}
        >
          登录
        </Button>
      </Box>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={3000} 
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert 
          severity={error ? "error" : "success"} 
          onClose={() => setSnackbarOpen(false)}
        >
          {error || '登录成功'}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
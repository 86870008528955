import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { Card, CardContent, Typography, Grid, Box, Divider, TextField, CircularProgress } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend } from 'recharts';

const AccountList = () => {
  const [accounts, setAccounts] = useState([]);
  const [spendHistoryData, setSpendHistoryData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  // 获取账户基本信息
  useEffect(() => {
    const token = localStorage.getItem('token');
    axiosInstance.get('/api/accounts/basic_info', { headers: { Authorization: token } })
      .then(response => {
        setAccounts(response.data);
      })
      .catch(error => {
        console.error("获取账户基本信息失败: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // 获取历史消费数据
  useEffect(() => {
    const token = localStorage.getItem('token');
    axiosInstance.get('/api/accounts/data_from_db', { headers: { Authorization: token } })
      .then(response => {
        const historyData = response.data.reduce((acc, account) => {
          acc[account.userName] = account.spendHistory;
          return acc;
        }, {});
        setSpendHistoryData(historyData);
      })
      .catch(error => {
        console.error("获取历史消费数据失败: ", error);
      });
  }, []);

  // 搜索框输入处理函数
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // 根据搜索框输入值过滤账户列表
  const filteredAccounts = accounts.filter(account =>
    account.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 如果正在加载，显示加载指示器
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>账户总览</Typography>
      
      {/* 搜索框 */}
      <TextField
        label="搜索账户"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 3 }}
      />

      {/* 账户信息卡片展示 */}
      <Grid container spacing={2}>
        {filteredAccounts.map((account, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                minWidth: 275,
                borderRadius: 2,
                boxShadow: 3,
                p: 2,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                }
              }}
            >
              <CardContent>
                <Typography variant="h6" align="left" gutterBottom>{account.userName}</Typography>
                <Divider sx={{ my: 1 }} />
                
                {/* 余额信息 */}
                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', mt: 1 }}>余额:</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    color: account.balance < 1000 ? 'error.main' : 'primary.main'
                  }}
                >
                  ¥{account.balance}
                </Typography>

                <Divider sx={{ my: 1 }} />
                
                {/* 当日消费金额 */}
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" color="textSecondary">当日消费金额:</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      color: account.dailySpend > 5000 ? 'warning.main' : 'text.primary'
                    }}
                  >
                    ¥{account.dailySpend}
                  </Typography>
                </Box>

                {/* 当日留线索数量 */}
                <Box>
                  <Typography variant="body2" color="textSecondary">当日留线索数量:</Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>{account.dailyLeads}</Typography>
                </Box>

                {/* 线索单价 */}
                <Box>
                  <Typography variant="body2" color="textSecondary">线索单价:</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: account.leadCost === 'N/A' ? 'text.secondary' : 'success.main'
                    }}
                  >
                    ¥{account.leadCost}
                  </Typography>
                </Box>

                {/* 消费趋势图 */}
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>消费趋势图:</Typography>
                  <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={spendHistoryData[account.userName] || []}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Line type="monotone" dataKey="spend" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>

                {/* 线索数量与单价对比直方图 */}
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>线索数量与单价对比:</Typography>
                  <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={spendHistoryData[account.userName] || []}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="leads" fill="#82ca9d" name="线索数量" />
                      <Bar dataKey="leadCost" fill="#8884d8" name="单价" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AccountList;

// src/components/ShareMccAccount.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { TextField, Button, Box, Typography, Container, Snackbar, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const ShareMccAccount = () => {
  const [mccAccounts, setMccAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [targetUsername, setTargetUsername] = useState('');
  const [permissionLevel, setPermissionLevel] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchMccAccounts = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axiosInstance.get('/api/mcc_accounts', { headers: { Authorization: token } });
        setMccAccounts(response.data);
      } catch (error) {
        console.error("获取管家账户数据失败: ", error);
      }
    };
    fetchMccAccounts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.post('/share_mcc_account',
        { mcc_account_id: selectedAccountId, target_username: targetUsername, permission_level: permissionLevel },
        { headers: { Authorization: token } }
      );
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      // 如果后端有返回错误信息，就显示它；否则显示网络错误
      const errorMessage = error.response && error.response.data && error.response.data.message 
                           ? error.response.data.message 
                           : '网络错误';
      setSnackbarMessage('共享失败：' + errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}>
        <Typography variant="h5" align="center" gutterBottom>共享管家账户</Typography>

        {/* 选择管家账户 */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="mcc-account-label">选择管家账户</InputLabel>
          <Select
            labelId="mcc-account-label"
            value={selectedAccountId}
            label="选择管家账户"
            onChange={(e) => setSelectedAccountId(e.target.value)}
            required
          >
            {mccAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.mcc_user_name} (ID: {account.id})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* 输入目标用户名 */}
        <TextField label="目标用户名" variant="outlined" fullWidth margin="normal" value={targetUsername} onChange={(e) => setTargetUsername(e.target.value)} required />

        {/* 输入权限级别 */}
        <TextField label="权限级别 (view/edit/admin)" variant="outlined" fullWidth margin="normal" value={permissionLevel} onChange={(e) => setPermissionLevel(e.target.value)} required />

        <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>共享账户</Button>
      </Box>

      {/* Snackbar 提示框 */}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ShareMccAccount;
